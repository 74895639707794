import { LoadingStateEnum } from '@dmc-ng/data-access';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { campaignReportingsActions } from './campaign-reportings.actions';
import { CampaignMetricModel } from '../models/campaign-reporting.model';
import { OverviewKPIModel, ReportingModel } from '../models/reporting.model';

export const CAMPAIGN_REPORTINGS_FEATURE_KEY = 'campaign-reportings';

export const campaignReportingsListAdapter: EntityAdapter<CampaignMetricModel> =
  createEntityAdapter<CampaignMetricModel>({
    selectId: (campaign) => campaign.campaignId,
  });
export const campaignReportingsDetailsAdapter: EntityAdapter<ReportingModel> =
  createEntityAdapter<ReportingModel>();

export interface CampaignReportingsState {
  campaignReportingsList: CampaignReportingsListState;
  kpi: OverviewKPIState;
  campaignReportingsDetails: CampaignReportingsDetailsState;
  loadingTokenShare: LoadingStateEnum;
}

export interface CampaignReportingsDetailsState
  extends EntityState<ReportingModel> {
  error?: unknown;
  loading: LoadingStateEnum;
}

export interface CampaignReportingsListState
  extends EntityState<CampaignMetricModel> {
  error?: unknown;
  total?: number;
  totalInCurrentOffset?: number;
  loading: LoadingStateEnum;
}

export interface OverviewKPIState {
  error?: unknown;
  loading: LoadingStateEnum;
  kpi?: OverviewKPIModel;
}

export const initialCampaignReportingsState: CampaignReportingsState = {
  campaignReportingsList: campaignReportingsListAdapter.getInitialState({
    loading: LoadingStateEnum.Ready,
  }),
  campaignReportingsDetails: campaignReportingsDetailsAdapter.getInitialState({
    loading: LoadingStateEnum.Ready,
  }),
  kpi: {
    loading: LoadingStateEnum.Ready,
  },
  loadingTokenShare: LoadingStateEnum.Ready,
};

const reducer = createReducer(
  initialCampaignReportingsState,
  on(campaignReportingsActions.getCampaignReportingsPaginated, (state) => ({
    ...state,
    campaignReportingsList: {
      ...state.campaignReportingsList,
      loading: LoadingStateEnum.Loading,
    },
  })),
  on(
    campaignReportingsActions.campaignReportingsPaginatedRetrievedSuccessfully,
    (state, { campaigns, total, totalInCurrentOffset }) => ({
      ...state,
      campaignReportingsList: {
        ...campaignReportingsListAdapter.setAll(
          campaigns,
          state.campaignReportingsList,
        ),
        loading: LoadingStateEnum.Done,
        total,
        totalInCurrentOffset,
      },
    }),
  ),
  on(
    campaignReportingsActions.campaignReportingsPaginatedFailedToBeRetrieved,
    (state, { error }) => ({
      ...state,
      campaignReportingsList: {
        ...state.campaignReportingsList,
        error,
        loading: LoadingStateEnum.Done,
      },
    }),
  ),
  on(campaignReportingsActions.getOverviewReportingKPI, (state) => ({
    ...state,
    kpi: {
      ...state.kpi,
      loading: LoadingStateEnum.Loading,
    },
  })),
  on(
    campaignReportingsActions.overviewReportingKPIRetrievedSuccessfully,
    (state, { kpi }) => ({
      ...state,
      kpi: {
        kpi: kpi,
        loading: LoadingStateEnum.Done,
      },
    }),
  ),
  on(
    campaignReportingsActions.overviewReportingKPIFailedToBeRetrieved,
    (state, { error }) => ({
      ...state,
      kpi: {
        ...state.kpi,
        error,
        loading: LoadingStateEnum.Done,
      },
    }),
  ),
  on(campaignReportingsActions.getCampaignReportingsDetails, (state) => ({
    ...state,
    campaignReportingsDetails: {
      ...state.campaignReportingsDetails,
      loading: LoadingStateEnum.Loading,
    },
  })),
  on(
    campaignReportingsActions.campaignReportingsDetailsRetrievedSuccessfully,
    (state) => ({
      ...state,
      campaignReportingsDetails: {
        ...state.campaignReportingsDetails,
        loading: LoadingStateEnum.Done,
      },
    }),
  ),
  on(
    campaignReportingsActions.campaignReportingsDetailsFailedToBeRetrieved,
    (state, { error }) => ({
      ...state,
      campaignReportingsDetails: {
        ...state.campaignReportingsDetails,
        error,
        loading: LoadingStateEnum.Done,
      },
    }),
  ),
  on(campaignReportingsActions.getTokenShare, (state) => ({
    ...state,
    loadingTokenShare: LoadingStateEnum.Loading,
  })),
  on(campaignReportingsActions.tokenShareRetrievedSuccessfully, (state) => ({
    ...state,
    loadingTokenShare: LoadingStateEnum.Done,
  })),
  on(
    campaignReportingsActions.tokenShareFailedToBeRetrieved,
    (state, { error }) => ({
      ...state,
      error,
      loadingTokenShare: LoadingStateEnum.Done,
    }),
  ),
);

export function campaignReportingsReducer(
  state: CampaignReportingsState | undefined,
  action: Action,
): CampaignReportingsState {
  return reducer(state, action);
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { APIResponse, PaginatedResponse } from '@dmc-ng/data-access';
import { API_BASE_URL } from '@dmc-ng/data-access/injection-token';
import { SortModel, SortUtils } from '@dmc-ng/utils';
import { Observable } from 'rxjs';

import { CampaignMetricModel } from './models/campaign-reporting.model';
import { OverviewKPIModel, ReportingModel } from './models/reporting.model';
import { TokenParamsModel, TokenShareModel } from './models/token.model';

@Injectable()
export class CampaignReportingsService {
  private readonly apiBaseUrl = inject(API_BASE_URL);

  constructor(private readonly httpClient: HttpClient) {}

  getCampaignReportingsPaginated(
    organizationId: string,
    accountId: string,
    startDate: string,
    endDate: string,
    limit?: number,
    offset?: number,
    sort?: SortModel,
  ): Observable<APIResponse<PaginatedResponse<CampaignMetricModel>>> {
    let params = new HttpParams()
      .append('start-date', startDate)
      .append('end-date', endDate);

    if (limit) {
      params = params.append('limit', limit);
    }

    if (offset) {
      params = params.append('offset', offset);
    }

    if (sort) {
      params = params.append('sort', SortUtils.createQueryParams(sort));
    }

    return this.httpClient.get<
      APIResponse<PaginatedResponse<CampaignMetricModel>>
    >(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/campaign-reportings/`,
      { params },
    );
  }

  getOverviewReportingKPI(
    organizationId: string,
    accountId: string,
    startDate: string,
    endDate: string,
  ): Observable<APIResponse<OverviewKPIModel>> {
    const params = new HttpParams()
      .append('start-date', startDate)
      .append('end-date', endDate);

    return this.httpClient.get<APIResponse<OverviewKPIModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/campaign-reportings/kpi`,
      { params },
    );
  }

  getCampaignReportingsDetails(
    organizationId: string,
    accountId: string,
    campaignId: string,
  ): Observable<APIResponse<ReportingModel>> {
    return this.httpClient.get<APIResponse<ReportingModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/campaign-reportings/${campaignId}`,
    );
  }

  postTokenShare(
    organizationId: string,
    accountId: string,
    campaignId: string,
    tokenParams: TokenParamsModel,
  ): Observable<APIResponse<TokenShareModel>> {
    return this.httpClient.post<APIResponse<TokenShareModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/campaign-reportings/${campaignId}/token`,
      tokenParams,
    );
  }
}

import { Injectable } from '@angular/core';
import { APIResponse, PaginatedResponse } from '@dmc-ng/data-access';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import { campaignReportingsActions } from './campaign-reportings.actions';
import { CampaignReportingsService } from '../campaign-reportings.service';
import { CampaignMetricModel } from '../models/campaign-reporting.model';
import { OverviewKPIModel, ReportingModel } from '../models/reporting.model';
import { TokenShareModel } from '../models/token.model';

@Injectable()
export class CampaignReportingsEffects {
  campaignReportings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(campaignReportingsActions.getCampaignReportingsPaginated),
      switchMap(
        ({
          accountId,
          organizationId,
          startDate,
          endDate,
          offset,
          limit,
          sort,
        }) =>
          this.campaignReportingsService
            .getCampaignReportingsPaginated(
              organizationId,
              accountId,
              startDate,
              endDate,
              limit,
              offset,
              sort,
            )
            .pipe(
              map(
                (
                  campaignsReportingResult: APIResponse<
                    PaginatedResponse<CampaignMetricModel>
                  >,
                ) => {
                  const { total, items } =
                    campaignsReportingResult.data as PaginatedResponse<CampaignMetricModel>;
                  return campaignReportingsActions.campaignReportingsPaginatedRetrievedSuccessfully(
                    {
                      campaigns: items as CampaignMetricModel[],
                      total,
                      totalInCurrentOffset: items.length,
                    },
                  );
                },
              ),
              catchError((error) =>
                of(
                  campaignReportingsActions.campaignReportingsPaginatedFailedToBeRetrieved(
                    {
                      error,
                    },
                  ),
                ),
              ),
            ),
      ),
    ),
  );

  campaignReportingsDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(campaignReportingsActions.getCampaignReportingsDetails),
      switchMap(({ accountId, organizationId, campaignId }) =>
        this.campaignReportingsService
          .getCampaignReportingsDetails(organizationId, accountId, campaignId)
          .pipe(
            map(
              (campaignReportingsDetailsResult: APIResponse<ReportingModel>) =>
                campaignReportingsActions.campaignReportingsDetailsRetrievedSuccessfully(
                  {
                    reporting:
                      campaignReportingsDetailsResult.data as ReportingModel,
                  },
                ),
            ),
            catchError((error) =>
              of(
                campaignReportingsActions.campaignReportingsDetailsFailedToBeRetrieved(
                  {
                    error,
                  },
                ),
              ),
            ),
          ),
      ),
    ),
  );

  campaignReportingsOverviewKPI$ = createEffect(() =>
    this.actions$.pipe(
      ofType(campaignReportingsActions.getOverviewReportingKPI),
      switchMap(({ accountId, organizationId, startDate, endDate }) =>
        this.campaignReportingsService
          .getOverviewReportingKPI(
            organizationId,
            accountId,
            startDate,
            endDate,
          )
          .pipe(
            map(
              (
                campaignReportingsOverviewKPIResult: APIResponse<OverviewKPIModel>,
              ) =>
                campaignReportingsActions.overviewReportingKPIRetrievedSuccessfully(
                  {
                    kpi: campaignReportingsOverviewKPIResult.data as OverviewKPIModel,
                  },
                ),
            ),
            catchError((error) =>
              of(
                campaignReportingsActions.overviewReportingKPIFailedToBeRetrieved(
                  {
                    error,
                  },
                ),
              ),
            ),
          ),
      ),
    ),
  );

  getTokenShared$ = createEffect(() =>
    this.actions$.pipe(
      ofType(campaignReportingsActions.getTokenShare),
      switchMap(({ organizationId, accountId, campaignId, tokenParams }) =>
        this.campaignReportingsService
          .postTokenShare(organizationId, accountId, campaignId, tokenParams)
          .pipe(
            map((result: APIResponse<TokenShareModel>) =>
              campaignReportingsActions.tokenShareRetrievedSuccessfully({
                token: (result.data as TokenShareModel).tokenValue,
              }),
            ),
            catchError((error) =>
              of(
                campaignReportingsActions.tokenShareFailedToBeRetrieved({
                  error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private campaignReportingsService: CampaignReportingsService,
  ) {}
}

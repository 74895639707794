import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  CampaignReportingsState,
  campaignReportingsListAdapter,
  CAMPAIGN_REPORTINGS_FEATURE_KEY,
  campaignReportingsDetailsAdapter,
} from './campaign-reportings.reducers';

export const selectCampaignReportingsState =
  createFeatureSelector<CampaignReportingsState>(
    CAMPAIGN_REPORTINGS_FEATURE_KEY,
  );

const {
  selectEntities: campaignReportingsListEntities,
  selectAll: campaignReportingsList,
} = campaignReportingsListAdapter.getSelectors();

const {
  selectEntities: campaignReportingsDetailsEntities,
  selectAll: allCampaignReportingsDetails,
} = campaignReportingsDetailsAdapter.getSelectors();

export const selectCampaignReportingsDetailsState = createSelector(
  selectCampaignReportingsState,
  (state) => state.campaignReportingsDetails,
);

export const selectCampaignReportingsListState = createSelector(
  selectCampaignReportingsState,
  (state) => state.campaignReportingsList,
);

export const selectCampaignReportingsOverviewKPIState = createSelector(
  selectCampaignReportingsState,
  (state) => state.kpi,
);

export const selectCampaignReportingsListEntities = createSelector(
  selectCampaignReportingsListState,
  campaignReportingsListEntities,
);

export const selectCampaignReportingsList = createSelector(
  selectCampaignReportingsListState,
  campaignReportingsList,
);

export const selectCampaignReportingsDetailsEntities = createSelector(
  selectCampaignReportingsDetailsState,
  campaignReportingsDetailsEntities,
);

export const selectAllCampaignReportingsDetails = createSelector(
  selectCampaignReportingsDetailsState,
  allCampaignReportingsDetails,
);

export const selectCampaignReportingsOverviewKPI = createSelector(
  selectCampaignReportingsOverviewKPIState,
  (state) => state.kpi,
);

export const selectCampaignReportingsListLoading = createSelector(
  selectCampaignReportingsListState,
  (state) => state.loading,
);

export const selectCampaignReportingsDetailsLoading = createSelector(
  selectCampaignReportingsDetailsState,
  (state) => state.loading,
);

export const selectCampaignReportingsOverviewKPILoading = createSelector(
  selectCampaignReportingsOverviewKPIState,
  (state) => state.loading,
);

export const selectCampaignReportingsListError = createSelector(
  selectCampaignReportingsListState,
  (state) => state.error,
);

export const selectCampaignReportingsDetailsError = createSelector(
  selectCampaignReportingsDetailsState,
  (state) => state.error,
);

export const selectCampaignReportingsOverviewKPIError = createSelector(
  selectCampaignReportingsOverviewKPIState,
  (state) => state.error,
);

export const selectCampaignReportingsListTotal = createSelector(
  selectCampaignReportingsListState,
  (state) => state.total,
);

export const selectCampaignReportingsListTotalInCurrentOffset = createSelector(
  selectCampaignReportingsListState,
  (state) => state.totalInCurrentOffset,
);

export const selectCampaignReportingTokenLoading = createSelector(
  selectCampaignReportingsState,
  (state) => state.loadingTokenShare,
);

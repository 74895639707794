import { SortModel } from '@dmc-ng/utils';
import { createActionGroup, props } from '@ngrx/store';

import { CampaignMetricModel } from '../models/campaign-reporting.model';
import { OverviewKPIModel, ReportingModel } from '../models/reporting.model';
import { TokenParamsModel } from '../models/token.model';

export const campaignReportingsActions = createActionGroup({
  source: 'Campaign Reportings',
  events: {
    'Get Campaign Reportings Paginated': props<{
      organizationId: string;
      accountId: string;
      startDate: string;
      endDate: string;
      offset?: number;
      limit?: number;
      sort?: SortModel;
    }>(),
    'Campaign Reportings Paginated retrieved successfully': props<{
      campaigns: CampaignMetricModel[];
      total: number;
      totalInCurrentOffset: number;
    }>(),
    'Campaign Reportings Paginated failed to be retrieved': props<{
      error: unknown;
    }>(),

    'Get Overview Reporting KPI': props<{
      organizationId: string;
      accountId: string;
      startDate: string;
      endDate: string;
    }>(),
    'Overview Reporting KPI retrieved successfully': props<{
      kpi: OverviewKPIModel;
    }>(),
    'Overview Reporting KPI failed to be retrieved': props<{
      error: unknown;
    }>(),

    'Get Campaign Reportings details': props<{
      organizationId: string;
      accountId: string;
      campaignId: string;
    }>(),
    'Campaign Reportings details retrieved successfully': props<{
      reporting: ReportingModel;
    }>(),
    'Campaign Reportings details failed to be retrieved': props<{
      error: unknown;
    }>(),
    'Get token share': props<{
      organizationId: string;
      accountId: string;
      campaignId: string;
      tokenParams: TokenParamsModel;
    }>(),
    'Token share retrieved successfully': props<{ token: string }>(),
    'Token share failed to be retrieved': props<{ error: unknown }>(),
  },
});
